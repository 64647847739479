import { BodyTitle, SubText } from "../atoms/Text";
import { styled } from "@mui/system";
import { useDropzone } from "react-dropzone";
import { WarningIcon } from "../atoms/icons/WarningIcon";
import { Button } from "../atoms/Button";
import { AnalyzationRequestTable } from "../molecules/AnalyzationRequestTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SleepwellApi } from "@sleepwell_new_platform/common-functions/lib/api";
import axios from "axios";
import { SimpleBackdrop } from "../molecules/Backdrop";
import useLocalStorage from "../../hooks/localstorage";
import { useQuery, QueryFunctionContext } from "react-query";
import dayjs from "dayjs";
import {
  Analyzation,
  AnalyzationStatus,
  Clinics,
  EquipmentRental,
  Patients,
} from "@sleepwell_new_platform/common-types";
import { Progress } from "../atoms/Progress";
import { convertAnalyzatonFileTime, yearArray } from "../../utils/analyzations";
import { getPatients } from "../../aws/api/patients";
import { calculateAge, getDay, getMonth, getYear } from "../../utils/commons";
import { systemType } from "../../config";
import CustomizedSnackbar from "../atoms/CustomizedSnackbar";
import { isAnalyzationProcessDone } from "../../utils/analyzations/analyzations";
import { edfRegex } from "../../utils/Validation";

const initAnalyzation = {
  id: "",
  clinicId: "clinicId",
  patientId: "",
  equipmentId: "",
  affiliationId: "",
  createdBy: "",
  updatedBy: "",
  createdAt: 0,
  updatedAt: 0,
  isDeleted: 0,
  analyzeServerTag: "",
  edfFileName: "",
  edfUrl: "",
  status: "starting",
  statusDetail: "",
  statusDetailEn: "",
  languageStatus: "",
  statusDetailAdmin: "",
  measureStartDate: 0,
  firstAnalyzeRequestDate: 0, // 初回の解析リクエスト日時
  lastAnalyzeRequestDate: 0, // 最後の解析リクエスト日時
  reCalcRequestDate: 0,
  lastRequestDate: 0, // 最後の解析リクエスト日時
  snoreQuestion: 0,
  athens: 0,
  birthday: "",
  age: 0,
  gender: "",
  medicine: "", // 別のtableに変えるかも
  diseaseHistory: "", // 別のtableに変えるかも
  reportLanguage: "",
  enableDepressionDiagnosis: 0,
  calcResult: "",
  analyzeServerId: "",
  analyzerVersionId: "",
  analyzerVersionName: "",
  moduleName: "",
  prefecture: "",
  reportCreated: 0,
  calcSucceeded: 0,
  birthMonth: "",
  birthYear: "",
  birthDay: "",
  districtId: "",
  customerSettings: "",
  anonymousReportStatusDetail: "",
  anonymousProcessedEdfFileName: "",
  commentAdminUser: "",
  commentCustomerUser: "",
  edfFileNameOrg: "",
  edfFileNameSearch: "",
  requestFileStorageUploaded: "",
  searchDiseaseCategoryIdList: "",
  searchDiseaseIdList: "",
  searchOtherDiseaseList: "",
  existsFiles: 0,
  fileType: "",
  isDepressionModule: 0,
  type: "analysis",
  isRead: 0,
  isFFTDownload: 0,
  isImmediateAnalyize: 0,
  sickHistory: "",
  medicineInfo: "",
  useOfDiseaseMedicalTreatment: 0,
} as unknown as Analyzation;

export type AnalyzationState = Analyzation & {
  file: File;
  rentalData: EquipmentRental;
  isExistFileName: boolean;
  isNotExistRetanlData: boolean;
  errorType: string;
};
type QueryKey = [string];
const edfdecoder = require("edfdecoder");

export const AnalyzationRequest = () => {
  const api = new SleepwellApi(axios);
  const [disabled, setIsDisabled] = useState<boolean>(false);
  const [analyaztionsState, setAnalyaztionsState] = useState<
    AnalyzationState[]
  >([]);
  const [snackState, setSnackState] = useState<{
    open: boolean;
    message: string;
    success: boolean;
  }>({
    open: false,
    message: "",
    success: false,
  });
  const [clinicId] = useLocalStorage("clinicId", "");
  const { data: result, isLoading } = useQuery("equipmentRental", () =>
    api.getEquipmentRentals(clinicId).then((res) => res.data)
  );
  const { data: patientsResult } = useQuery("patients", () =>
    getPatients(clinicId).then((res) => res.data)
  );

  const { data: moduleVersions, isLoading: isLoadingGetModuleVersions } =
    useQuery(
      ["moduleVersions"],
      async (context: QueryFunctionContext<QueryKey>) => {
        return await api.getModuleVersions("");
      }
    );
  const { data: clinicResult } = useQuery(
    "clinic",
    async () => await api.getClinics(clinicId)
  );
  const { data: diseases } = useQuery("diseases", () =>
    api.getDiseases().then((res) => res.data.result.Items)
  );
  const { data: affiliation } = useQuery('affiliations', ()=>
  api.getAffiliations(clinicId).then((res) => res.data)
);

  const clinic = useMemo(() => {
    return clinicResult ? clinicResult[0] : null;
  }, [clinicResult]);

  const readAsArrayBufferAsync = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles: string | any[], fileRejections: string | any[]) => {
      if (!clinic) return;
      if (fileRejections.length > 0) {
        if (fileRejections[0].errors[0]?.code === "file-too-large") {
          setSnackState({
            open: true,
            message:
              "ファイルサイズが上限を超えています。10MB以下のサイズでアップロードしてください",
            success: false,
          });
          return;
        } else if (fileRejections[0].errors[0]?.code === "file-invalid-type") {
          setSnackState({
            open: true,
            message: "edfのファイル形式でアップロードしてください",
            success: false,
          });
          return;
        } else {
          window.alert(fileRejections[0].errors[0]?.code);
          return;
        }
      }
      if (analyaztionsState.length >= 10) {
        setSnackState({
          open: true,
          message: "アップロードするファイルは10ファイル以内にしてください",
          success: false,
        });
        return;
      }
      if (analyaztionsState.length + acceptedFiles.length > 10) {
        setSnackState({
          open: true,
          message: "アップロードするファイルは10ファイル以内にしてください",
          success: false,
        });
        return;
      }
      if (acceptedFiles.length > 0) {
        for (let index = 0; index < acceptedFiles.length; index++) {
          let isError = false;
          const file = acceptedFiles[index];
          if (!file) return;
          const equipmentId = file.name.substring(0, 3);
          const equipmentRentals = result?.result.equipmentRentals.Items;
          const moduleVersionsData = moduleVersions?.data.result.Items;
          const isExistFileName = equipmentRentals?.filter(
            (equipmentRental) => equipmentRental.equipmentId === equipmentId
          );
          const isExistSameEDFFile = await api.getAnalyzation(
            clinic?.id,
            undefined,
            file.name.replace(".txt", ".EDF")
          );
          // ここでEDF+ファイルを解析する
          let measureStartDate = 0;
          const fileNameArray = file.name.split(".");
          if (
            file.name.split(".")[fileNameArray.length - 1]?.toLowerCase() ===
            "edf"
          ) {
            const arrayBuffer = await readAsArrayBufferAsync(file);
            let decoder = new edfdecoder.EdfDecoder();
            decoder.setInput(arrayBuffer);
            decoder.decode();
            let myEdf = decoder.getOutput();
            const YYYY =
              "20" +
              String(myEdf._header.recordingDate.getFullYear()).slice(-2);
            const MM = myEdf._header.recordingDate.getMonth();
            const DD = myEdf._header.recordingDate.getDate();
            const HH = myEdf._header.recordingDate.getHours();
            const mm = myEdf._header.recordingDate.getMinutes();
            const ss = myEdf._header.recordingDate.getSeconds();
            // ここでedfDataを使ってさらなる処理を行います。
            measureStartDate = dayjs(
              `${YYYY}-${MM}-${DD} ${HH}:${mm}:${ss}`
            ).valueOf();
            // EDFファイル場合のヘッダー確認
            if((myEdf._header.nbDataRecords + 1) * 512 !== file.size) {
              isError = true;
            }
          }
          if (file.name.split(".")[1] === "txt") {
            //TXTファイルの場合
            measureStartDate =
              isExistSameEDFFile.data.result.Items[0]?.measureStartDate;
          }
          // EDFファイル名をここで生成する
          // EDFファイル名から機器貸出期間に入っているか確認する
          // ファイル名の検証
          const filteredRentalData = equipmentRentals?.filter(
            (rental) => rental.equipmentId === equipmentId
          );
          const startMeasurement = dayjs(measureStartDate);
          const isExistRetanlData =
            filteredRentalData?.filter(
              (rental) =>
                dayjs(rental.startAt).isBefore(startMeasurement) &&
                dayjs(rental.endAt).isAfter(startMeasurement)
            ) ?? [];
          const moduleType = clinic?.isEnableDepressionFunction
            ? "depression"
            : "sleep";
          const rentalData = isExistRetanlData[0];
          const sortedModuleVersions = moduleVersionsData
            ?.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
            .filter(
              (moduleVersion) =>
                moduleVersion.isValid && moduleVersion.type === moduleType
            );
          if (!sortedModuleVersions || sortedModuleVersions.length === 0) {
            setSnackState({
              open: true,
              message: "モジュールバージョンの取得に失敗しました",
              success: false,
            });
            return;
          }
          const patient =
            patientsResult?.result.Items.find(
              (patient) => patient.id === rentalData?.patientId
            ) ?? null;
          // 同一ファイルがあり && 再解析許可されている場合
          // inProgressの場合エラーがメッセージ
          // doneの場合は再解析が可能
          // 再計算はしていない状態
          if (
            isReadyForReAnalyze(isExistSameEDFFile.data.result.Items, clinic)
          ) {
            setAnalyaztionsState((prevState) => {
              const params = {
                ...isExistSameEDFFile.data.result.Items[0],
                file: file,
                rentalData: rentalData ?? null,
                fileType: file.type,
                gender: patient?.gender ?? "",
                lastAnalyzeRequestDate: dayjs().valueOf(),
                lastRequestDate: dayjs().valueOf(),
                isExistFileName: isExistFileName ? true : false,
                isNotExistRetanlData: isExistRetanlData ? false : true,
                errorType: getReAnalysisErrorType(
                  file.name,
                  isExistSameEDFFile.data.result.Items,
                  isExistRetanlData,
                  patient,
                  clinic,
                  isError
                ),
                isDepressionModule: clinic?.isEnableDepressionFunction ? 1 : 0,
                measureStartDate: measureStartDate,
                isImmediateAnalyize: clinic?.isEnabledImmediateAnalyize ? 1 : 0,
                status: "starting" as "starting",
                type: "reAnalysis" as "reAnalysis",
                updatedBy: localStorage?.id ?? "",
                isRead: 0,
              };
              if (prevState) {
                // 同じファイル名のファイルがある場合は、上書きする
                const isExistSameFileName = prevState.find(
                  (state) => state.edfFileName === file.name
                );
                if (isExistSameFileName) {
                  return [
                    ...prevState.filter(
                      (state) => state.edfFileName !== file.name
                    ),
                    params,
                  ];
                } else {
                  return [...prevState, params];
                }
              } else {
                return [params];
              }
            });
          } else {
            const diseaseList = patient?.diseases?.map((disease, index) => {
              return {
                isMain: index === 0 ? 1 : 0,
                diseaseName: diseases?.find((d) => d.id === disease.diseasesId)?.name ?? ""
,               firstVisitDate: disease.firstVisitDate ?? "",
                other: disease.other,
              };
            }) ?? [];  
            setAnalyaztionsState((prevState) => {
              const params = {
                ...initAnalyzation,
                equipmentId: file.name.substring(0, 3),
                clinicId: rentalData?.clinicId,
                patientId: rentalData?.patientId,
                edfFileName: file.name,
                affiliationId: patient?.affiliationId ?? "",
                file: file,
                rentalData: rentalData ?? null,
                fileType: file.type,
                gender: patient?.gender ?? "",
                age: patient?.birthday
                  ? calculateAge(patient?.birthday, measureStartDate)
                  : 0,
                birthday: patient?.birthday ?? "",
                birthYear: patient?.birthday ? getYear(patient?.birthday) : "",
                birthMonth: patient?.birthday
                  ? getMonth(patient?.birthday)
                  : "",
                birthDay: patient?.birthday ? getDay(patient?.birthday) : "",
                analyzerVersionId: sortedModuleVersions[0].id,
                analyzerVersionName: sortedModuleVersions[0].versionName,
                firstAnalyzeRequestDate: dayjs().valueOf(),
                lastAnalyzeRequestDate: dayjs().valueOf(),
                lastRequestDate: dayjs().valueOf(),
                moduleName: clinic?.isEnableDepressionFunction
                  ? "SEAS-D"
                  : "SEAS-G",
                prefecture: patient?.prefecture ?? "",
                medicine: patient?.medicineInfo ?? "",
                isExistFileName: isExistFileName ? true : false,
                isNotExistRetanlData: isExistRetanlData ? false : true,
                errorType: getErrorType(
                  file.name,
                  isExistSameEDFFile.data.result.Items,
                  isExistRetanlData,
                  clinic,
                  patient,
                  isError
                ),
                isDepressionModule: clinic?.isEnableDepressionFunction ? 1 : 0,
                measureStartDate: measureStartDate,
                isImmediateAnalyize: clinic?.isEnabledImmediateAnalyize ? 1 : 0,
                createdBy: localStorage?.id ?? "",
                updatedBy: localStorage?.id ?? "",
                sickHistory: patient?.sickHistory ?? "",
                medicineInfo: patient?.medicineInfo ?? "",
                diseaseList: [
                  ...diseaseList
                ],
                useOfDiseaseMedicalTreatment: clinic?.useOfDiseaseMedicalTreatment === true ? 1 : 0,
                clinic: clinic,
                patient: patient,
                affiliation: affiliation?.result.affiliations.Items?.find((doc) => doc.id === patient?.affiliationId) ?? null,
              };
              if (prevState) {
                // 同じファイル名のファイルがある場合は、上書きする
                const isExistSameFileName = prevState.find(
                  (state) => state.edfFileName === file.name
                );
                if (isExistSameFileName) {
                  return [
                    ...prevState.filter(
                      (state) => state.edfFileName !== file.name
                    ),
                    params,
                  ];
                } else {
                  return [...prevState, params];
                }
              } else {
                return [params];
              }
            });
          }
        }
      }
    },
    [
      result,
      moduleVersions?.data.result.Items,
      patientsResult,
      clinic,
      systemType,
      clinic?.isEnabledImmediateAnalyize,
      analyaztionsState.length,

    ]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      //  'application/octet-stream': [],
    },
    onDrop,
    // maxSize: 10485760,
    // maxFiles: 1,
  });
  const uploadEDFFiles = async (/*tableName: string, id: string, e: any*/) => {
    try {
      const id = localStorage?.id ?? "";
      setIsDisabled(true);
      if (analyaztionsState && analyaztionsState?.length > 0 && id) {
        for (let index = 0; index < analyaztionsState.length; index++) {
          const analyaztionState = analyaztionsState[index];
          // memo: アラートが上がってるものはアップしない
          if (analyaztionState.errorType !== "") continue;
          const response = await api.getSignedUrl(
            analyaztionState.edfFileName,
            analyaztionState.patientId,
            analyaztionState.clinicId,
            "put",
            analyaztionState.fileType
          );
          // await api.uploadFile(tableName, id, e.target.files[0]);,
          const img_options = {
            headers: {
              "Content-Type": analyaztionState.fileType, //fileState.type,
            },
          };
          await axios.put(
            response.data.result,
            analyaztionState.file,
            img_options
          );
          if (analyaztionState.id === "") {
            api
              .postAnalyzation({
                ...analyaztionState,
                edfUrl: decodeURIComponent(response.data.result.split("?")[0]),
                firstAnalyzeRequestDate: dayjs().valueOf(),
                updatedAt: dayjs().valueOf(),
                createdAt: dayjs().valueOf(),
              })
              .catch((err) => {
                setSnackState({
                  open: true,
                  message: "解析依頼に失敗しました",
                  success: false,
                });
                return;
              });
          } else {
            // データ更新の場合
            api
              .putAnalyzation({
                ...analyaztionState,
                edfUrl: decodeURIComponent(response.data.result.split("?")[0]),
                updatedAt: dayjs().valueOf(),
              })
              .catch((err) => {
                setSnackState({
                  open: true,
                  message: "解析依頼に失敗しました",
                  success: false,
                });
                return;
              });
          }
          setAnalyaztionsState((prev) => {
            const newState = prev?.concat();
            if(!newState) return [];
            return newState.filter((state) => state.edfFileName !== analyaztionState.edfFileName);
          }
          );
        }
        setSnackState({
          open: true,
          message: "解析依頼が完了しました",
          success: true,
        });
      }
      setIsDisabled(false);
      setAnalyaztionsState([]);
    } catch (e) {
      console.log(e);
    }
  };
  const deleteEDFFile = (index: number) => {
    setAnalyaztionsState((prev) => {
      const newState = prev?.concat();
      if (newState) newState.splice(index, 1);
      return newState;
    });
  };
  if (isLoading || isLoadingGetModuleVersions) return <Progress />;
  return (
    <Wrap>
      <BodyTitle style={{ marginBottom: 16 }}>{"解析依頼"}</BodyTitle>
      <SubText fontcolor="black">
        {"・"}
        <StyledSpan>
          {"ファイル名は「.EDF」で終わるファイル名にしてください。"}
        </StyledSpan>
        {"（大文字と小文字は区別します。）"}
      </SubText>
      <SubText fontcolor="black">
        {
          "・ファイル名にはスペースを含めないでください。また、ファイル名に使用可能な記号は「-」「_」のみです。"
        }
      </SubText>
      <SubText fontcolor="black">
        {"・解析するファイルが多い場合は、10個程度ずつアップロードしてください"}
      </SubText>
      <FileWrap {...getRootProps()}>
        <input {...getInputProps()} disabled={false} />
        {isDragActive ? (
          <DropErea>{"ファイルをアップロードしてください"}</DropErea>
        ) : (
          <DropErea>{"ファイルをアップロードしてください"}</DropErea>
        )}
      </FileWrap>
      <WarningWrap>
        <SubText
          fontcolor="black"
          style={{ display: "flex", alignItems: "center", marginRight: 8 }}
        >
          <WarningIcon />
          {"ご利用のPCの負荷が高いなどでブラウザがフリーズした場合"}
        </SubText>
        <SubText fontcolor="black">
          {
            "1. 一旦ブラウザを閉じて（強制終了）、再度開いてログインしなおしてください。"
          }
        </SubText>
        <SubText fontcolor="black">
          {
            "2. 解析・レポート一覧で、解析が受け付けられているかをご確認ください。解析されていない場合は再度解析依頼をお願いいたします。"
          }
        </SubText>
      </WarningWrap>
      <TableWrap>
        <AnalyzationRequestTable
          analyaztionsState={analyaztionsState}
          patients={patientsResult?.result.Items ?? []}
          deleteEDFFile={deleteEDFFile}
          clinic={clinic}
          moduleVersions={moduleVersions?.data.result.Items ?? []}
          setAnalyaztionsState={setAnalyaztionsState}
        />
      </TableWrap>
      <ButtonWrap>
        <Button
          width={210}
          children={"依頼する"}
          disabled={
            analyaztionsState.length === 0 ||
            !analyaztionsState.some((state) => state.errorType === "") ||
            disabled
          }
          onClick={() => uploadEDFFiles()}
        />
      </ButtonWrap>
      <SimpleBackdrop
        open={disabled}
        message={"アップロード中です。しばらくお待ちください..."}
      />
      <CustomizedSnackbar
        setSnackState={setSnackState}
        snackState={snackState}
      />
    </Wrap>
  );
};

//.EDFで終わっていない場合
const isNotValidFileName = (fileName: string) => {
  return !fileName.toLowerCase().endsWith(".edf");
};

// ファイル名に対応する貸出期間がない場合
const isNoRentalData = (rentalData?: EquipmentRental[]) => {
  if (!rentalData || rentalData.length === 0) return true;
  return false;
};

// 再解析が解析が無効で、同一ファイル名がすでにある場合
const isExistSameFile = (analyaztions: Analyzation[], clinic: Clinics) => {
  if (analyaztions.length === 0) return false;
  if (!clinic?.isEnabledReAnalyize && analyaztions.length > 0) return true;
};
// 再解析が有効でステータスが未完了の場合
const isNotReadyForReAnalyze = (
  analyaztions: Analyzation[],
  clinic: Clinics
) => {
  if (
    clinic?.isEnabledReAnalyize &&
    analyaztions.length > 0 &&
    !isAnalyzationProcessDone(analyaztions[0].status as AnalyzationStatus)
  )
    return true;
  return false;
};
const isReadyForReAnalyze = (analyaztions: Analyzation[], clinic: Clinics) => {
  if (analyaztions[0]?.reCalcRequestDate) return false;
  // 再解析許可されているかつ、ステータスが完了している場合（analyaztionsがある）
  if (
    clinic?.isEnabledReAnalyize &&
    analyaztions.length > 0 &&
    isAnalyzationProcessDone(analyaztions[0].status as AnalyzationStatus)
  )
    return true;
  return false;
};
const isDoneReCalc = (analyaztions: Analyzation[], clinic: Clinics) => {
  if (
    analyaztions[0]?.reCalcRequestDate &&
    analyaztions[0]?.reCalcRequestDate > 0
  )
    return true;
  //　再アップロードで、即時解析が有効でない場合
  if(analyaztions.length > 0 && !clinic?.isEnabledImmediateAnalyize) return true;
  // 再アップロードで、再解析が有効でない場合
  if(analyaztions.length > 0 && !clinic?.isEnabledReAnalyize) return true;
  // 再アップロードで、レポート作成済みの場合
  if(analyaztions.length > 0 && analyaztions[0]?.status === "confirmed") return true;
  return false;
};
const isPublishedReport = (analyaztions: Analyzation[]) => {
  if (
    analyaztions &&
    analyaztions.length > 0 &&
    analyaztions[0]?.status === "confirmed"
  )
    return true;
  return false;
};
const isNotValidFileNameFormat = (fileName: string) => {
  if (!edfRegex.test(fileName)) return true;
  const baseName = fileName.substring(0, fileName.lastIndexOf('.'));
  if (baseName.endsWith('_')) return true;
  return false;
};

const getReAnalysisErrorType = (
  fileName: string,
  existSameEDFFileItems: Analyzation[], // 実際の型に応じて型を調整してください。
  existRentalData: EquipmentRental[], // 実際の型に応じて型を調整してください。
  patient: Patients | null,
  clinic: Clinics, // 実際の型に応じて型を調整してください。
  isErrorFile: boolean
) => {
  if  (isErrorFile) return "isErrorFile";
  if (isNotValidFileName(fileName)) return "isNotValidFileName";
  if (isNotValidFileNameFormat(fileName)) return "isNotValidFileNameFormat";
  if (isPublishedReport(existSameEDFFileItems)) return "isPublishedReport";
  if (isNoRentalData(existRentalData)) return "isNoRentalData";
  if (isDoneReCalc(existSameEDFFileItems, clinic)) return "isDoneReCalc";
  
  if (!patient) return "isNoPatient";
  return ""; // どの条件にも該当しない場合は空文字列を返す
};

const getErrorType = (
  fileName: string,
  existSameEDFFileItems: Analyzation[], // より適切な型が存在する場合、こちらを利用してください。
  existRetanlData: EquipmentRental[],
  clinic: Clinics, // より適切な型が存在する場合、こちらを利用してください。
  patient: Patients | null,
  isErrorFile: boolean
) => {
  if  (isErrorFile) return "isErrorFile";
  if (isNotValidFileName(fileName)) return "isNotValidFileName";
  if (isNotValidFileNameFormat(fileName)) return "isNotValidFileNameFormat";
  if (isPublishedReport(existSameEDFFileItems)) return "isPublishedReport";
  if (isNoRentalData(existRetanlData)) return "isNoRentalData";
  if (isExistSameFile(existSameEDFFileItems, clinic)) return "isExistSameFile";
  if (isNotReadyForReAnalyze(existSameEDFFileItems, clinic))
    return "isNotReadyForReAnalyze";
  if (isDoneReCalc(existSameEDFFileItems, clinic)) return "isDoneReCalc";
  if (!patient) return "isNoPatient";

  return "";
};

const Wrap = styled("div")({
  marginRight: 60,
  width: "100%",
  maxWidth: 1200,
});

const WarningWrap = styled("div")({
  padding: "16px",
  background: "#F8F8F8",
  margin: "36px 0 36px 0",
});

const FileWrap = styled("div")({
  padding: "16px",
  background: "#FFFFFF",
  margin: "24px 0 40px 0",
  textAlign: "center",
  border: "1px solid #ABD7F1",
  cursor: "pointer",
});

const DropErea = styled("div")({
  padding: "16px",
  background: "#FFFFFF",
  margin: "88px 0 88px 0",
  color: "#529BC5",
  fontWeight: 700,
});

const StyledSpan = styled("span")({
  color: "#DB7E29",
});

const TableWrap = styled("div")({
  marginBottom: 24,
});

const ButtonWrap = styled("div")({
  marginBottom: 36,
});
