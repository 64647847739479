import { Box, styled } from "@mui/material";
import { GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams, GridSortModel, GridSortItem, GridRowParams, MuiEvent, GridSelectionModel, GridCellParams } from "@mui/x-data-grid";
import { Affiliation, Analyzation, Clinics, Patients } from "@sleepwell_new_platform/common-types";
import { IsNotReadLabel, IsReadLabel } from "../../../icons";
import MuiLink from '@mui/material/Link';
import { ArrowIcon } from "../../../icons/analyzation";
import dayjs from "../../../lib/dayjs";
import { getAnalyzationStatusLabel, isAnalyzationProcessDone } from "../../../utils/analyzations";
import React, { useMemo, useState } from "react";
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { clinicEndopoint } from "@sleepwell_new_platform/common-functions/lib/api";
import { AnalyzationStatus } from "../../../utils/analyzations/analyzations";


type Props = {
  analyzations: Analyzation[];
  handleDownloadEDF: (data: Analyzation) => void;
  handleDownloadSingleFFT: (data: Analyzation) => void;
  patients: Patients[];
  affiliations: Affiliation[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAnalyzationState: React.Dispatch<React.SetStateAction<Analyzation | undefined>>;
  setSelectList: React.Dispatch<React.SetStateAction<string[]>>;
  setOpenReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDocA3ReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDocA4ReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  updateAnalyzation: (data: Analyzation) => Promise<void>;
  clinic: Clinics | null;
  setIsOpenRejectModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectList: string[];
  loading: boolean;
  sortModel: GridSortModel;
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
  paginationModel: {page: number, pageSize: number};
  setPaginationModel: React.Dispatch<React.SetStateAction<{
    page: number;
    pageSize: number;
}>>;
  filterFlag: boolean;
  count: number;
  start: number;
  group: string | null
}


export const DataGrid = (props: Props) => {
  const [pinnedState, setPinnedState] = useState<{left: string[], right: string[]}>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'isRead', 'operation', 'report', 'fftFileName', 'edfFileName', 'status'],
    right: []
  });
  const { analyzations, handleDownloadEDF, patients, affiliations, setOpen, setAnalyzationState, setSelectList, setOpenReportModal, updateAnalyzation, clinic, setIsOpenRejectModal, selectList, handleDownloadSingleFFT, setOpenDocA3ReportModal, setOpenDocA4ReportModal, loading, sortModel, setSortModel, paginationModel, setPaginationModel, filterFlag, count, start, group } = props;
  const columns: any[] = useMemo(() => {
    let normalColumns = [
      {
        field: 'isRead',
        headerName: 'ステータス',
        width: 100,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          const isProcessDone = params.row.status === 'confirmed';
          const isRead = params.row?.isRead ? params.row?.isRead === 0 ? false : true : false;
          // モーダルが開く
          return (
            <>
              {!isProcessDone ? <></> : isRead ? <IsReadLabel /> : <IsNotReadLabel />}
            </>
          )
        }
      },
      {
        field: 'operation',
        headerName: '操作',
        width: 100,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          // モーダルが開く
          // AnalyzationStatus.REQUEST_FOR_RE_MEASURE, AnalyzationStatus.FAILED
          const isProcessDone = ['confirmed' , 'failed'].includes(params.row.status);
          return (
            <Wrapper>
              {isProcessDone &&
                <FlexWrap>
                  <StyledMuiLink
                    onClick={() => {
                      setAnalyzationState(params.row);
                      setOpen(true)
                    }}
                    style={{ marginRight: 8 }}>
                    解析情報
                  </StyledMuiLink>
                  <ArrowIcon />
                </FlexWrap>}
            </Wrapper>
          )
        }
      },
      {
        field: 'report',
        headerName: 'レポート',
        width: 150,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          const isProcessDone =  ['confirmed'].includes(params.row.status);
          // レポートページを表示する
          //console.log(clinic?.reportType)
          return (
            <Wrapper>
              {isProcessDone &&
                <FlexWrap>
                  <StyledMuiLink onClick={() => {
                    if(["ドック・医療機関向けA3", "doctorA3"].includes(clinic?.reportType ?? "")){
                      setOpenDocA3ReportModal(true);
                    }
                    if(["ドック・医療機関向けA4", "doctorA4"].includes(clinic?.reportType ?? "")){
                      setOpenDocA4ReportModal(true);
                    }
                    if(["睡眠変数詳細版（研究者向け）", "research"].includes(clinic?.reportType ?? "")){
                      setOpenReportModal(true);
                    }
                    setAnalyzationState(params.row);
                    if (!params.row.isRead || params.row.isRead === 0) {
                      updateAnalyzation({ ...params.row, isRead: 1 })
                    }
                  }}
                    style={{ marginRight: 8 }}>
                    レポート表示
                  </StyledMuiLink>
                  <ArrowIcon />
                </FlexWrap>}
            </Wrapper>
          )
        }
      },
      {
        field: 'fftFileName',
        headerName: 'FFT解析結果',
        width: 120,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          //ここで署名付きダウンロードを行う
          const isProcessDone = params.row.status === 'confirmed';
          return (
            <>
              {isProcessDone && <FlexWrap>
                <StyledMuiLink onClick={() => handleDownloadSingleFFT(params.row)}>
                  {'ダウンロード'}
                </StyledMuiLink>
              </FlexWrap>}
            </>
          )
        }
      },
      {
        field: 'edfFileName',
        headerName: 'EDFファイル名',
        width: 200,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          //ここで署名付きダウンロードを行う
          return (
            <FlexWrap>
              <StyledMuiLink onClick={() => handleDownloadEDF(params.row)}>
                {params.row.edfFileName}
              </StyledMuiLink>
            </FlexWrap>
          )
        }
      },
      {
        field: 'status',
        headerName: '解析状態',
        width: 210,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          if (isAnalyzationProcessDone(params.row.status)) {
            if(['failed', 'confirmed'].includes(params.row.status)) {
              return getAnalyzationStatusLabel(params.row.status);
            } else {
              return <LinkWrap onClick={() => {
                setAnalyzationState(params.row);
                setIsOpenRejectModal(true);
              }}
              isProcessDone={!isAnalyzationProcessDone(params.row)} 
              >{getAnalyzationStatusLabel(params.row.status)}</LinkWrap>
            }
          } else {
            return <LinkWrap onClick={() => {
              setAnalyzationState(params.row);
              //setIsOpenRejectModal(true);
            }}
            isProcessDone={isAnalyzationProcessDone(params.row)} 
            >{getAnalyzationStatusLabel(params.row.status)}</LinkWrap>
          }
        }
      },
      {
        field: 'rejectMesssage',
        headerName: '解析メッセージ',
        width: 350,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          const analyzation = params.row as Analyzation
          const rejectMessageList = analyzation.rejectMessageList;
          let message = ''
          if (params.row?.status === "failed") {
            message = 'このデータは処理できない可能性があります。スリープウェルでデータを確認しますので、しばらくお待ちください。'
            return <StylyedRejectMessage>{message}</StylyedRejectMessage>
          }
          if(!rejectMessageList){
            return <></>
          }
          const isProcessDone = isAnalyzationProcessDone(analyzation?.status as AnalyzationStatus);
            message = Array.from(new Set(rejectMessageList.filter((doc) => isProcessDone && doc.userMessage !== "スリープウェルにて解析結果を検証中です。しばらくお待ちください。").map((doc) => doc.userMessage))).join("\n");
          if(analyzation?.status === AnalyzationStatus.WAITING_FOR_CONFIRM  || analyzation?.status === AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT){
            message = "スリープウェルにて解析結果を検証中です。しばらくお待ちください。"
          }
          if(analyzation?.status === "confirmed" || analyzation?.status === "waitingForReCalculating"){
            message = ''
          }
          return   <StylyedRejectMessage>{message}</StylyedRejectMessage>
        }
      },
      {
        field: 'equipmentId',
        headerName: '機器ID',
        width: 100,
      },
      {
        field: 'moduleName',
        headerName: '解析プログラム',
        width: 150,
        valueGetter: (params: GridValueGetterParams<Analyzation>) => {
          const moduleName = params.row.moduleName;
          return moduleName
        },
      },
      {
        field: 'version',
        headerName: 'バージョン',
        width: 150,
        valueGetter: (params: GridValueGetterParams<Analyzation>) => {
          const analyzerVersionName = params.row.analyzerVersionName
          return analyzerVersionName
        },
      },
      {
        field: 'affiliation',
        headerName: '所属',
        width: 150,
        valueGetter: (params: GridValueGetterParams<any>) => {
          const affiliationName = affiliations.find(affiliation => affiliation.id === params.row.affiliationId)?.name ?? "";
          return affiliationName;
        }
      },
      {
        field: 'patientNumber',
        headerName: '受診者番号',
        width: 150,
        valueGetter: (params: GridValueGetterParams<any>) => {
          const patientNumber = patients.find(patient => patient.id === params.row.patientId)?.patientNumber ?? "";
          return patientNumber;
        }
      },
      {
        field: 'patientName',
        headerName: '受診者',
        width: 150,
        valueGetter: (params: GridValueGetterParams<any>) => {
          const patientName = patients.find(patient => patient.id === params.row.patientId)?.name ?? "";
          return patientName;
        }
      },
      {
        field: 'measureStartDate',
        headerName: '測定開始日',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams<any>) => {
          return params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
      },
      {
        field: 'age',
        headerName: '年齢',
        width: 100,
        valueGetter: (params: GridValueGetterParams<any>) => {
          const age = params.row.age;
          return age
        },
      },
      {
        field: 'gender',
        headerName: '性別',
        width: 100,
      },
      {
        field: 'firstAnalyzeRequestDate',
        headerName: '登録日（初回解析）',
        width: 170,
        valueFormatter: (params: GridValueFormatterParams<any>) => {
          return params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
      },
      {
        field: 'lastAnalyzeRequestDate',
        headerName: '再解析依頼日',
        width: 160,
        valueGetter:(params: GridValueGetterParams<Analyzation>) => {
          const lastAnalyzeRequestDate = params.row.lastAnalyzeRequestDate;
          const firstAnalyzeRequestDate = params.row.firstAnalyzeRequestDate;
          const timeDifference = lastAnalyzeRequestDate - firstAnalyzeRequestDate; // ミリ秒単位での時間差
          const timeDifferenceInMinutes = timeDifference / (1000 * 60); 
          const isReAnalysis = timeDifferenceInMinutes > 2;
          return !isReAnalysis ? '-' : dayjs(lastAnalyzeRequestDate).format('YYYY-MM-DD HH:mm:ss'); 
        }
      },
    ];
    const isAllowedSelectModule = clinic?.isAllowedSelectModule;
    const isInputPatientName = clinic?.isInputPatientName;
    if (!isAllowedSelectModule) {
      normalColumns = normalColumns.filter(column => !(column.field === 'moduleName' || column.field === 'version'))
    }
    if (!isInputPatientName) {
      normalColumns = normalColumns.filter(column => !(column.field === 'patientName'))
    }
    if(!clinic?.isAllowedFFTDownload){
      normalColumns = normalColumns.filter(column => !(column.field === 'fftFileName'))
    }
    if(group === 'coMedical') {
      normalColumns = normalColumns.filter((column) =>  !(['operation', 'report', 'fftFileName'].includes(column.field)))
    }
    return normalColumns;
  }, [clinic, patients, affiliations, selectList, updateAnalyzation, setAnalyzationState, setOpen, setOpenReportModal, handleDownloadEDF, handleDownloadSingleFFT, setIsOpenRejectModal, setOpenDocA3ReportModal, setOpenDocA4ReportModal, selectList, analyzations, loading, group]);

  const handleSortModelChange = (newSortModel: GridSortItem[]) => {
    setSortModel(newSortModel as GridSortModel); // // ソートモデルの変更を反映
  };
  return (
    <Box sx={{ height: '75vh', width: '100%' }}>
      <StyledDataGrid
        checkboxSelection
        loading={loading}
        columns={columns}
        rows={analyzations}
        onRowSelectionModelChange={(newSelection: any) => {
          if (newSelection?.length > 0) {
            const updatedSelection = newSelection.filter((id: any) =>
              analyzations.find(row => row.id === id && isAnalyzationProcessDone(row.status as AnalyzationStatus))
            );
            setSelectList(updatedSelection as string[]);
          }
        }}
        onPinnedColumnsChange={(newPinnedState: any) => {
          setPinnedState(newPinnedState);
        }}
        onPaginationModelChange={(newModel) => {
          if(!filterFlag){
          setPaginationModel(newModel)
          }
        }}
        disableRowSelectionOnClick
        columnHeaderHeight={40}
        rowHeight={40}
        pagination
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        localeText={{ noRowsLabel: 'データがありません' }}
        pinnedColumns={{ left: pinnedState.left, right: pinnedState.right }}
        paginationMode={filterFlag ? 'client' : 'server'}
        pageSizeOptions={[50]}
        rowCount={count ?? undefined}
      />
    </Box>
  )
}

const FlexWrap = styled('div')`
display: flex;
align-items: center;
cursor: pointer;
`;

const StyledDataGrid = styled(DataGridPro)({
  border: 'none',
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#898989',
    borderRadius: '0px',
    border: 'none',
    color: '#FFF',
    //height: '32px !important',
    //minHeight: 'auto !important',
  },
  '.MuiDataGrid-columnHeader': {
    backgroundColor: '#898989',
    padding: '0 10',
    //height: '32px',
    minHeight: 'auto !important',
  },
  '.MuiDataGrid-columnHeaderTitleContainer': {
    backgroundColor: '#898989',
    borderRadius: '0px',
    border: 'none',
    color: '#FFF',
    //height: '32px',
    //minHeight: 'auto !important',
  },
  /*'.MuiDataGrid-virtualScroller': {
    marginTop: '32px !important',
  },*/
  '.MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '.MuiDataGrid-cellContent': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold'
  }
});

interface LinkWrapProps {
  isProcessDone: boolean;
}

const LinkWrap = styled('div')<LinkWrapProps>`
color: ${props => props.isProcessDone ?  '#0077C7' : 'red'};
&:hover {
  text-decoration: ${props => props.isProcessDone ?  'underline' : 'none'};
};
cursor: ${props => props.isProcessDone ?  'pointer' : 'none'};
`;

const StyledMuiLink = styled(MuiLink)`
&& {
  text-decoration: none;
  margin-right: 8px;

  &:hover {
    text-decoration: underline;
  }
}
`;

const Wrapper = styled('div')`
min-width: 60px;
`;
const StylyedRejectMessage = styled('div')`
overflow: hidden;
white-space: pre-line;
max-height: 2.6em;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
`;
